import {LocalDatePicker, LocalDatePickerProps} from '@thx/controls';
import {toLocalDate} from '@thx/date';
import {useGetFiscalDatePickerMinDateQuery} from '~common/components/FiscalDatePicker/getFiscalDatePickerMinDate';
import {useAsyncError} from '~lib/useAsyncError';
import {useAccountState} from '../../../accounts/state';

export function FiscalDatePicker(props: LocalDatePickerProps) {
	const {accountInfoId} = useAccountState();
	const throwAsync = useAsyncError();
	const {data, loading, error} = useGetFiscalDatePickerMinDateQuery({
		variables: {
			accountInfoId: accountInfoId || '',
		},
		skip: !accountInfoId,
	});

	if (error) throwAsync(error);

	const minDate = data?.getLastClosedDate ? toLocalDate(data.getLastClosedDate).plusDays(1) : undefined;
	return <LocalDatePicker {...props} minDate={minDate} disabled={loading || props.disabled} tabIndex={props.disabled ? -1 : 0} />;
}
